import React, { useContext, useEffect, useRef } from "react";
import Loading from "../../../components/Loading/Loading";
import { LoginContext } from "../../../components/LoginProvider/LoginProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";
import { SignUpFlowLocationType } from "../types/SignUpFlowLocationType";
import { navigateToCredentials } from "../utils/navigateToCredentials";
import { PaymentError } from "../components/PaymentError";
import { PaymentMethodSelect } from "../components/PaymentMethodSelect";
import StripeSubscribeSection from "../components/StripeSubscribeSection";
import PayPalSubscribeSection from "../components/PayPalSubscribeSection";
import { PaymentProvider } from "../contexts/PaymentProvider";
import { isBrowser } from "../../../utils/ssr";
import { DDBlueIcon } from "../icons/DDBlueIcon";
import { Button } from "../../../components/FormElement/Button";

const SubscriptionPaymentPageV4 = ({ location }: { location?: SignUpFlowLocationType }) => {
  const { loading: loginLoading, user, loggedIn } = useContext(LoginContext);
  const paymentDetailsRef = useRef<HTMLDivElement>(null);

  const [isContinue, setIsContinue] = React.useState(false);

  // Handle logged out user
  useEffect(() => {
    if (!loginLoading && !user && !loggedIn) {
      navigateToCredentials("account");
    }
  }, [loggedIn, loginLoading, user]);

  useEffect(() => {
    if (isBrowser()) {
      window.localStorage.setItem("selectedPlan", JSON.stringify("ANNUAL"));
    }
  }, []);

  // Full screen loading
  if (loginLoading) {
    return (
      <div className="bg-white min-h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <SEOReactHelmet title={"Diet Doctor - Payment"} noindex />
      <PaymentProvider>
        <div className="border-b border-grey w-full hidden md:flex flex-row items-center justify-between mb-8">
          <DDBlueIcon className="my-4 mx-8" />
        </div>

        <main className="mx-auto flex flex-col items-center bg-white text-dark-grey w-full max-w-md px-5 overflow-visible">
          {/* Introduction */}
          <section className="flex flex-col items-center mt-4">
            <DDBlueIcon height={39} className="self-start mt-2.5 md:hidden" />

            <div className="bg-[#C2CFE5] text-blue rounded-lg px-4 py-1 pb-0.5 max-w-max text-base font-medium">
              Limited time offer
            </div>

            <h1 className="text-black m-0 mt-4 font-normal text-[1.5rem]">Try Diet Doctor Plus for 30 days</h1>

            <p className="m-0 mt-2 text-blue text-base">Invest in your best self</p>
          </section>

          {/* Thematic break */}
          <hr className="border-0 border-b border-light-grey opacity-60 w-full mt-4"></hr>

          {/* Bullet points */}
          <section>
            <ul className="self-start space-y-2 p-0 m-0 mt-4 text-base p-0">
              <CheckMarkPoint>Personalized meal plans tailored to reach your goals</CheckMarkPoint>
              <CheckMarkPoint>1200+ recipes delicious healthy recipes</CheckMarkPoint>
              <CheckMarkPoint>
                Life changing programs for weight loss, diabetes, sugar addiction and much more
              </CheckMarkPoint>
              <CheckMarkPoint>Tips and guidance from nutritional experts</CheckMarkPoint>
              <CheckMarkPoint>You won’t be charged until after your free trial. Cancel anytime.</CheckMarkPoint>
            </ul>
          </section>

          {/* Continue button */}
          {!isContinue && (
            <Button
              type="button"
              label="Continue"
              className="w-full bg-green mt-4"
              onClick={() => setIsContinue(true)}
            />
          )}

          {isContinue && (
            <>
              {/* Free trial info */}
              <div className="mt-[1.875rem] text-center">
                <h4 className="font-normal text-[1.5rem]">Start your free 30-day trial</h4>
                <p className="text-[1.063rem] mt-1.5">No commitment. Cancel anytime.</p>
              </div>

              {/* Thematic break */}
              <hr className="border-0 border-b border-light-grey opacity-60 w-full mt-6"></hr>

              {/* Final price */}
              <div className="mt-6 w-full">
                <div className="flex flex-row justify-between">
                  <p className="font-medium text-lg text-green">Total due today</p>
                  <p className="font-medium text-lg">$0.00</p>
                </div>
                <div className="flex flex-row justify-between mt-2">
                  <p className="text-lg">Cost per month</p>
                  <p className="text-lg">US $8.25</p>
                </div>
                <p className="text-[0.941rem] mt-2 text-left mx-auto">
                  US $99 automatically billed after trial.{" "}
                  <span className="whitespace-nowrap">Cancel anytime.</span>
                </p>
              </div>

              {/* Error box */}
              <PaymentError />

              {/* Payment input */}
              <section
                className="mt-7 md:mt-[4.25rem] md:mb-64 w-screen md:w-full"
                style={{ marginLeft: "calc(50% - 50vw)", marginRight: "calc(50% - 50vw)" }}
              >
                <div className="bg-[#F3F2EE] md:-m-10 pt-7 pb-64 md:pt-7 md:pb-7 md:px-7 md:rounded-2xl">
                  <div className="mx-auto max-w-md px-5 overflow-hidden">
                    <div className="flex flex-row items-center justify-between">
                      <h4 className="text-2xl self-start font-normal">Payment details</h4>
                      <div className="m-0 flex flex-row space-x-2.5 items-center">
                        <span className="opacity-80 -mb-1">Secure payment</span>{" "}
                        <svg height="22.5" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.5 9.882c-.414 0-.75.369-.75.824v9.881c0 .455.336.824.75.824h12c.414 0 .75-.369.75-.824v-9.881c0-.455-.336-.824-.75-.824h-12zm-2.25.824c0-1.365 1.007-2.471 2.25-2.471h12c1.243 0 2.25 1.106 2.25 2.47v9.882c0 1.365-1.007 2.47-2.25 2.47h-12c-1.243 0-2.25-1.105-2.25-2.47v-9.881z"
                            fill="#000001"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.257 1.93C5.383.694 6.91 0 8.5 0c1.591 0 3.117.694 4.243 1.93C13.868 3.165 14.5 4.84 14.5 6.588v2.47c0 .455-.336.824-.75.824S13 9.513 13 9.058v-2.47c0-1.31-.474-2.567-1.318-3.494-.844-.926-1.989-1.447-3.182-1.447-1.193 0-2.338.52-3.182 1.447C4.474 4.021 4 5.278 4 6.588v2.47c0 .455-.336.824-.75.824s-.75-.369-.75-.824v-2.47c0-1.747.632-3.423 1.757-4.658z"
                            fill="#000001"
                          />
                        </svg>
                      </div>
                    </div>
                    <h5 className="text-lg mt-3 mb-2 self-start font-normal">Choose a payment method:</h5>
                    {/* Select payment method */}
                    <PaymentMethodSelect
                      debitCreditText={"Debit/credit"}
                      paypalText={"PayPal"}
                      scrollToRef={paymentDetailsRef}
                    />
                    {/* Payment method input, PayPal & Stripe */}
                    <div ref={paymentDetailsRef}>
                      {user?.userId && (
                        <>
                          <StripeSubscribeSection />
                          <PayPalSubscribeSection />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </main>
      </PaymentProvider>
    </>
  );
};

const CheckMarkPoint = ({ className, children }: { className?: string; children?: React.ReactNode }) => (
  <li className={`flex flex-row items-start space-x-2 ${className ?? ""}`}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2 flex-shrink-0"
    >
      <path d="M20 6L9 17l-5-5" stroke="#0E3478" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    <p>{children}</p>
  </li>
);

export default React.memo(SubscriptionPaymentPageV4);
